.inputFeild {
  font-size: 15px;
  width: 98%;
  height: 35px;
  border-radius: 6px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.inputFeild:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.productTitle {
  color: Gray;
}

.paymentImage {
  width: 70%;
  margin-top: 15%;
}

.firstname {
  width: 49%;
  float: left;
  border-radius: 6px 1px 1px 6px;
  border-right: none;
}

.lastname {
  width: 48%;
  float: left;
  border-radius: 1px 6px 6px 1px;
}

.name {
  float: left;
  padding-right: 170px;
}

.cardNumber {
  width: 99%;
  box-sizing: border-box;
  border-radius: 6px 6px 1px 1px;
  padding: 8px 12px;
  margin-bottom: 0px;
}

.cardExpiration {
  box-sizing: border-box;
  width: 50%;
  float: left;
  border-top: none;
  border-radius: 1px 1px 1px 6px;
  padding: 8px 12px;
}

.cardCvc {
  box-sizing: border-box;
  width: 49%;
  float: left;
  border-top: none;
  border-left: none;
  border-radius: 1px 1px 6px 1px;
  padding: 8px 12px;
}

.paymentButton {
  width: 98%;
}

/* loading spinner begins */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 2px;
  width: 4px;
  background: salmon;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 2px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 8px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 14px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 2px;
    height: 16px;
  }
  50%,
  100% {
    top: 6px;
    height: 8px;
  }
}
/* loading spinner ends */
