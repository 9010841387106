.page {
  position: absolute;
  left: 8%;
  top: 7%;
}

.proAccount * {
  display: inline;
  vertical-align: top;
}
